import clsx from 'clsx';
// eslint-disable-next-line no-restricted-imports
import { ImageProps } from 'next/image';
import Link, { LinkProps } from 'next/link';
import { HTMLAttributeAnchorTarget, HTMLAttributes, useMemo, useState } from 'react';

import Image from '@/components/image/Image';

import styles from './ImageSlider.module.scss';

type Props = LinkProps &
  HTMLAttributes<HTMLAnchorElement> & {
    target: HTMLAttributeAnchorTarget;
    images: {
      alt: string;
      url: string;
      title: string;
    }[];
    imageProps?: Omit<ImageProps, 'alt' | 'src'>;
  };

// The required distance between touchStart and touchEnd to be detected as a swipe
const MIN_SWIPE_DISTANCE = 70;

export const ImageSlider = ({ className, images, imageProps, ...rest }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const activeImage = useMemo(() => images[activeIndex], [activeIndex]);

  const onTouchStart = (e: any) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > MIN_SWIPE_DISTANCE;
    const isRightSwipe = distance < -MIN_SWIPE_DISTANCE;

    if (isLeftSwipe) {
      activeIndex < images.length - 1 ? setActiveIndex(activeIndex + 1) : setActiveIndex(0);
    }
    if (isRightSwipe) {
      activeIndex > 0 ? setActiveIndex(activeIndex - 1) : setActiveIndex(images.length - 1);
    }
  };

  const onSetActiveSlide = (index: number) => setActiveIndex(index);

  const onResetSlider = () => setActiveIndex(0);

  return (
    <Link
      {...rest}
      className={clsx(styles.wrapper, className)}
      onMouseLeave={onResetSlider}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <Image
        src={activeImage.url}
        alt={activeImage.alt}
        title={activeImage.title}
        fill
        itemProp="image"
        priority={true}
        {...imageProps}
      />
      <div className={styles.zones}>
        {images.map((_, index) => (
          <div key={index} onMouseEnter={() => onSetActiveSlide(index)} />
        ))}
      </div>
      {images.length > 1 && (
        <div className={styles.dots}>
          {images.map((_, index) => (
            <span key={index} className={clsx({ [styles.active]: index === activeIndex })}></span>
          ))}
        </div>
      )}
    </Link>
  );
};
