import AzCoinIcon from './az-coin.png';
import DolyameSmallIcon from './dolyami.png';
import FavoriteHeartSvg from './favorite-heart.svg';
import HeightSvg from './height.svg';
import PodelySmallIcon from './podely.png';
import PriceLineSvg from './price-line.svg';
import SplitSmallIcon from './split.png';
import WidthSvg from './width.svg';

export {
  PodelySmallIcon,
  DolyameSmallIcon,
  SplitSmallIcon,
  PriceLineSvg,
  FavoriteHeartSvg,
  WidthSvg,
  HeightSvg,
  AzCoinIcon,
};
