import clsx from 'clsx';
// eslint-disable-next-line no-restricted-imports
import { ImageProps } from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CSSProperties, Fragment, HTMLAttributes, memo } from 'react';

import Image from '@/components/image/Image';
import { ImageSlider } from '@/components/product-card/image-slider/ImageSlider';

import { analyticMetric } from '@/helpers/analytics-metric';
import { formatCurrency } from '@/helpers/number/format-currency';
import { countBonus } from '@/helpers/string/case-choise';
import { getImageFormatUrl } from '@/helpers/string/getImageFormatUrl';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useHasMounted } from '@/hooks/useHasMounted';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import {
  addProduct,
  sumCount,
  totalCount,
} from '@/store/slices/checkout-product/checkout-product.slice';
import {
  addToFavorites,
  removeFromFavorites,
} from '@/store/slices/favorites-products/favorites-products.slice';

import { IProductNew } from '@/types/new/products';

import { ROUTES } from '@/constants/routes.constant';

import styles from './ProductCard.module.scss';
import * as Assets from './assets';

type ProductCardProps = HTMLAttributes<HTMLDivElement> & {
  product: IProductNew;
  analyticMetricClickEvent?: () => void;
  analyticMetricAddToCartEvent?: () => void;
  imageProps?: Omit<ImageProps, 'alt' | 'src'>;
};

const amount = 1;

const ProductCard = ({
  product,
  analyticMetricClickEvent,
  analyticMetricAddToCartEvent,
  imageProps,
  ...props
}: ProductCardProps) => {
  const { name, sale, price, slug, media, is_in_stock, diameter, height } = product;
  const { cashback } = useTypedSelector((state) => state.user);

  const router = useRouter();
  const dispatch = useAppDispatch();
  const hasMounted = useHasMounted();
  const favoriteProducts = useTypedSelector((state) => state.favoriteProducts.products);
  const checkoutProducts = useTypedSelector((state) => state.checkoutProducts.products);
  const hiddenCssProps: CSSProperties = { display: 'none' };

  const inFav =
    hasMounted && favoriteProducts.length
      ? Boolean(favoriteProducts.find((el) => el.id === product.id))
      : false;

  const inCart =
    hasMounted && checkoutProducts.length
      ? Boolean(checkoutProducts.find((el) => el?.product?.id === product.id))
      : false;

  const returnBonusCount = countBonus(cashback, price);

  const onCardClick = (e: any) => {
    analyticMetricClickEvent && analyticMetricClickEvent();
  };

  const addHandler = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    analyticMetric.hitGoal('nazhali-na-knopku-zakazat-na-kartochku-tovara');
    analyticMetricAddToCartEvent && analyticMetricAddToCartEvent();

    if (!inCart) {
      const localStorageProduct = {
        product,
        amount: +amount,
      };

      dispatch(addProduct(localStorageProduct));

      dispatch(sumCount());
      dispatch(totalCount());
    } else {
      await router.push('/checkout');
    }
  };

  const favoriteHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    inFav ? dispatch(removeFromFavorites({ id: product.id })) : dispatch(addToFavorites(product));
    !inFav && analyticMetric.addToWishList(product);
  };

  return (
    <article
      {...props}
      className={styles.wrapper}
      itemScope
      itemType="https://schema.org/Product"
      onClick={onCardClick}
    >
      <ImageSlider
        className={styles.images}
        href={ROUTES.PRODUCT(slug)}
        target="_blank"
        rel="noopener"
        images={media.slice(0, 4).map((image, index) => ({
          url: getImageFormatUrl(image, 'small'),
          alt: `Фото ${index + 1}: ${name}. Сервис доставки цветов AzaliaNow`,
          title: name,
        }))}
        imageProps={imageProps}
      />

      <Link href={ROUTES.PRODUCT(slug)} target="_blank" rel="noopener" className={styles.body}>
        <div className={styles.top}>
          <span className={styles.name} itemProp="name">
            {name}
          </span>
          {!!diameter && !!height && (
            <>
              <div className={styles.sizes}>
                Высота: {height} см • Ширина: {diameter} см
              </div>
              <div className={clsx(styles.sizes, styles.mobile)}>
                <span>
                  <Assets.HeightSvg /> {height} см
                </span>
                <span>
                  <Assets.WidthSvg /> {diameter} см
                </span>
              </div>
            </>
          )}
        </div>

        <div className={styles.bottom}>
          <div
            className={styles.prices}
            itemProp="offers"
            itemScope
            itemType="https://schema.org/Offer"
          >
            <span itemProp="priceCurrency" style={hiddenCssProps}>
              RUB
            </span>
            <span itemProp="price" style={hiddenCssProps}>
              {price.toFixed(2)}
            </span>
            <meta
              itemProp="priceValidUntil"
              content={
                new Date(new Date().setDate(new Date().getDate() + 2)).toISOString().split('T')[0]
              }
            />
            <link
              itemProp="availability"
              href={is_in_stock ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock'}
              style={hiddenCssProps}
            />
            <span className={styles.currentPrice}>{formatCurrency(price)}</span>
            {!!sale && (
              <span className={styles.oldPrice}>
                {formatCurrency(Math.ceil(price / (1 - sale)))}
                <Assets.PriceLineSvg />
              </span>
            )}
          </div>
          <div className={styles.partsSystems}>
            {is_in_stock && (
              <Fragment>
                <div className={styles.icons}>
                  <Image src={Assets.DolyameSmallIcon} width={16} height={16} alt="Иконка Долями" />
                  <Image src={Assets.PodelySmallIcon} width={16} height={16} alt="Иконка Подели" />
                  <Image src={Assets.SplitSmallIcon} width={16} height={16} alt="Иконка Сплит" />
                </div>
                <div className={styles.text}>
                  Частями по {formatCurrency(Math.floor(price / 4))} <span>x4</span>
                </div>
                <div className={clsx(styles.text, styles.mobile)}>
                  по {formatCurrency(Math.floor(price / 4))} <span>x4</span>
                </div>
              </Fragment>
            )}
          </div>
        </div>

        {!!returnBonusCount && hasMounted && (
          <div className={styles.bonusLabel}>
            +{returnBonusCount}
            <Image src={Assets.AzCoinIcon} alt="Иконка АзалияКоин" width={12} height={12} />
          </div>
        )}
      </Link>

      <div className={styles.footer}>
        <button
          className={clsx(styles.buyButton, inCart && styles.inCart)}
          disabled={!is_in_stock}
          aria-label="Кнопка добавить в корзину"
          onClick={addHandler}
        >
          {!is_in_stock && 'Нет в наличии'}
          {is_in_stock && !inCart && 'В корзину'}
          {is_in_stock && inCart && 'В корзине'}
        </button>
      </div>

      <button
        className={clsx(styles.favoriteButton, inFav && styles.active)}
        aria-label="Кнопка добавить в избранное"
        onClick={favoriteHandler}
      >
        <Assets.FavoriteHeartSvg />
      </button>
    </article>
  );
};

export default memo(ProductCard);
